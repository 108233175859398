<template>
    <section class="content">
        <div class="overlay-wrapper">
            <div class="overlay" v-if="loadingContent">
                <h1 style="font-size: 40px">
                    Sedang diproses
                    <i class="fas fa-sync-alt fa-spin"></i>
                </h1>
            </div>
            <form role="form" @submit.prevent="submitForm">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <!-- <div class="col-md-4 form-group">
                    <label class="control-label">TANGGAL</label>
                    <datepicker v-model="form.tanggal" :options="{ orientation: 'bottom', format: 'dd/mm/yyyy', autoclose: true }" id="tanggal" />
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="control-label">KURIR</label>
                        <select required v-model="form.courier_id" id="courier_id" name="courier_id" class="form-control">
                            <option v-for="courier in totalCourier" v-bind:key="courier.courier_id" v-bind:value="courier.courier_id">
                                {{ courier.label }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4 form-group">
                        <label class="control-label">REKENING</label>
                        <v-select :options="totalBank" v-model="form.rekening_id" :reduce="opt => opt.rekening_id" />
                    </div> -->
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <label class="control-label"
                                    >UPLOAD PRODUK PPOB</label
                                >
                                <button
                                    class="btn btn-secondary mb-3 float-right"
                                    @click.prevent="downloadTemplate"
                                >
                                    <i class="fa fa-download"></i> Unduh
                                    Template
                                    <span
                                        v-if="loadingDownload"
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                </button>
                            </div>
                            <div class="col-md-12">
                                <form
                                    action="/target"
                                    ref="dzone"
                                    class="dropzone card"
                                    style="border-style: dashed"
                                >
                                    <div class="overlay" v-if="loading">
                                        <div
                                            class="spinner-border"
                                            role="status"
                                        >
                                            <span class="sr-only"
                                                >Loading...</span
                                            >
                                        </div>
                                    </div>
                                    <div
                                        class="dz-message text-muted"
                                        data-dz-message
                                    >
                                        <h4>
                                            <div>
                                                <i class="fa fa-upload"></i>
                                            </div>
                                            <span>Upload file Anda disini</span>
                                        </h4>
                                    </div>
                                </form>
                                <span
                                    class="text-muted"
                                    v-if="!msg ? false : true"
                                    >{{ msg }}</span
                                >
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button
                            type="submit"
                            :disabled="loading2"
                            class="btn btn-info"
                            id="submit"
                        >
                            Cek
                            <span
                                v-if="loading2"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </button>
                        <!-- <button
                            type="submit"
                            :disabled="loading2"
                            class="btn btn-primary"
                            id="submit"
                        >
                            Simpan
                            <span
                                v-if="loading2"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </button> -->
                    </div>
                </div>
            </form>
            <form role="form" @submit.prevent="submitFinalForm">
                <div class="p-2"></div>
                <div class="card">
                    <div class="card-body">
                        <div class="col-md-12">
                            <label class="control-label"
                                >PREVIEW PRODUK PPOB</label
                            >
                        </div>
                        <div class="table-responsive">
                            <table
                                class="table table-hover"
                                ref="tblppobproducts"
                                id="tblppobproducts"
                            >
                                <thead>
                                    <tr>
                                        <th>KATEGORI</th>
                                        <th>PRODUK</th>
                                        <th>KODE PRODUK</th>
                                        <th>TAG</th>
                                        <th>TIPE</th>
                                        <th>HARGA BELI (OLD)</th>
                                        <th>HARGA BELI (NEW)</th>
                                        <th>HARGA JUAL (OLD)</th>
                                        <th>HARGA JUAL (NEW)</th>
                                        <th>ADMIN PROVIDER (PASCA)</th>
                                        <th>ADMIN SHIPPER (PASCA)</th>
                                        <th>Denom (Khusus PLN)</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button
                            type="submit"
                            :disabled="loading3"
                            class="btn btn-primary"
                            id="submit"
                        >
                            Simpan
                            <span
                                v-if="loading3"
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from "@/libs/hxcore";
import Dropzone from "dropzone";
import XLSX from "xlsx";
import Swal from "sweetalert2";
import $ from "jquery";
import "dropzone/dist/dropzone.css";
import datepicker from "@/components/Datepicker";
import moment from "moment";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
    name: "FormUploadPpobProduct",
    data() {
        return {
            errors: [],
            roles: "",
            msg: "",
            loading: false,
            loading2: false,
            loading3: false,
            loadingDownload: false,
            data: [],
            formTitle: "Tambah",
            form: {
                tanggal: moment().format("DD/MM/YYYY"),
                courier_id: "",
                rekening_id: "",
            },
            formData: [],
            totalCourier: [],
            totalBank: [],
            disabled: true,
            file: {},
            loadingContent: "",
        };
    },
    components: {
        datepicker,
        vSelect,
    },
    computed: {},
    created: function () {
        authFetch("/shipper/kurir")
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
            })
            .then((js) => {
                this.totalCourier = js.data;
            });

        this.loadBank();
        this.form.tanggal = moment().format("DD/MM/YYYY");
        $(".loading-overlay").removeClass("show");
    },
    methods: {
        loadBank: function () {
            authFetch("/payment/saldo/bank")
                .then((res) => {
                    if (res.status === 201) {
                    } else if (res.status === 400) {
                    }
                    return res.json();
                })
                .then((js) => {
                    this.totalBank = js.data;
                });
        },
        btnFile() {
            var input = document.querySelector("#excelFile");
            input.click();
        },
        downloadTemplate: function () {
            this.loadingDownload = true;
            authFetch("/file/aset/template_product_item", {
                method: "GET",
            })
                .then((response) => response.blob())
                .then((blob) => {
                    this.loadingDownload = false;
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = "template_product_item.xlsx";
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove(); //afterwards we remove the element again
                });
        },
        submitForm: function (ev) {
            var self = this;
            const e = this.$refs;

            var allData = { group: self.form, items: self.formData };
            var urlSubmit = "/ppob/products_upload";

            var formData = new FormData();
            formData.append("userfile", this.file);

            this.loading2 = true;
            // $("#tblppobproducts tbody").empty();
            Swal.fire({
                title: "Upload?",
                showCancelButton: true,
                confirmButtonText: `Ya`,
                cancelButtonText: "Tidak",
            }).then((result) => {
                // $("#tblppobproducts").DataTable();
                this.loadingContent = true;
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    $("#tblppobproducts").DataTable().destroy();
                    // $("#tblppobproducts").DataTable().clear();
                    authFetch(urlSubmit, {
                        method: "POST",
                        body: formData,
                        headers: {
                            "Content-Type": null,
                        },
                    })
                        .then((res) => {
                            if (res.status === 201) {
                            } else if (res.status === 400) {
                            } else if (res.status === 500) {
                                Swal.fire("Timeout!", ``, "error");
                                this.loadingContent = false;
                                this.loading2 = false;
                                this.msg = "";
                            }
                            return res.json();
                        })
                        .then((js) => {
                            $("#tblppobproducts tbody").empty();
                            this.loadingContent = false;
                            this.loading2 = false;
                            let data = js.data;

                            /* console.log(
                                "ppob product",
                                JSON.stringify(js.data)
                            ); */

                            if (js.success) {
                                /* Swal.fire("Proses Berhasil", ``, "success");
                                this.form = {};
                                this.msg = ""; */

                                let tbody = $("#tblppobproducts tbody");
                                data.forEach(function (item) {
                                    let color = "";
                                    let color2 = "";
                                    let product_cost_old =
                                        !item.product_cost_old
                                            ? 0
                                            : item.product_cost_old * 1;
                                    let product_cost_new =
                                        !item.product_cost_new
                                            ? 0
                                            : item.product_cost_new * 1;
                                    let product_price_old =
                                        !item.product_price_old
                                            ? 0
                                            : item.product_price_old * 1;
                                    let product_price_new =
                                        !item.product_price_new
                                            ? 0
                                            : item.product_price_new * 1;
                                    let product_admin_provider =
                                        !item.product_admin_provider_check
                                            ? 0
                                            : item.product_admin_provider_check *
                                              1;
                                    let product_admin_shipper =
                                        !item.product_admin_shipper_check
                                            ? 0
                                            : item.product_admin_shipper_check *
                                              1;

                                    let denom = !item.denom_check ? 0 : item.denom_check * 1;
                                    if (
                                        product_cost_old > 0 &&
                                        product_cost_new < product_cost_old
                                    ) {
                                        color = "red";
                                    }

                                    if (
                                        product_price_old > 0 &&
                                        product_price_new < product_price_old
                                    ) {
                                        color2 = "red";
                                    }

                                    var row = `<tr>
                                        <td>${
                                            !item.product_category_check
                                                ? ""
                                                : item.product_category_check
                                        }</td>
                                        <td>${
                                            !item.product_name_check
                                                ? ""
                                                : item.product_name_check
                                        }</td>
                                        <td>${
                                            !item.product_code_check
                                                ? ""
                                                : item.product_code_check
                                        }</td>
                                        <td>${
                                            !item.product_tag_check
                                                ? ""
                                                : item.product_tag_check
                                        }</td>
                                        <td>${
                                            !item.product_type_check
                                                ? ""
                                                : item.product_type_check
                                        }</td>
                                        <td style="font-weight: bold;">${product_cost_old}</td>
                                        <td style="color: ${color}; font-weight: bold;">${product_cost_new}</td>
                                        <td style="font-weight: bold;">${product_price_old}</td>
                                        <td style="color: ${color2}; font-weight: bold;">${product_price_new}</td>
                                        <td style="font-weight: bold;">${product_admin_provider}</td>
                                        <td style="font-weight: bold;">${product_admin_shipper}</td>
                                        <td style="font-weight: bold;">${denom}</td>
                                    </tr>`;

                                    tbody.append(row);
                                });

                                // Initialize DataTable after data is appended
                                $("#tblppobproducts").DataTable();
                            } else {
                                Swal.fire(
                                    "Proses Gagal",
                                    `Produk PPOB mungkin tidak sesuai format penulisan. Silahkan cek kembali`,
                                    "error"
                                );
                            }
                        });
                }
            });

            ev.preventDefault();
        },
        submitFinalForm: function (ev) {
            var self = this;
            const e = this.$refs;
            var urlSubmit = "/ppob/products_save";
            let tbl = $("#tblppobproducts").DataTable();
            var items = [];
            tbl.rows().every(function (rowIdx, tableLoop, rowLoop) {
                var d = this.data();
                items.push(d);
            });

            var allData = { data: items };

            this.loading3 = true;
            Swal.fire({
                title: "Simpan Data?",
                showCancelButton: true,
                confirmButtonText: `Ya`,
                cancelButtonText: "Tidak",
            }).then((result) => {
                this.loadingContent = true;
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    $("#tblppobproducts").DataTable().destroy();
                    authFetch(urlSubmit, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(allData),
                    })
                        .then((res) => {
                            if (res.status === 201) {
                            } else if (res.status === 400) {
                            } else if (res.status === 500) {
                                Swal.fire("Timeout!", ``, "error");
                                this.loadingContent = false;
                                this.loading3 = false;
                                this.msg = "";
                            }
                            return res.json();
                        })
                        .then((js) => {
                            $("#tblppobproducts tbody").empty();
                            this.loadingContent = false;
                            this.loading3 = false;
                            if (js.success) {
                                Swal.fire(
                                    "Proses Berhasil",
                                    `Berhasil simpan produk PPOB.`,
                                    "success"
                                );
                                this.$router.push("/ppob/products");
                                /* this.form = {};
                                this.msg = ""; */
                            } else {
                                Swal.fire(
                                    "Proses Gagal",
                                    `Terjadi kesalahan ketika hendak menyimpan data. Silahkan cek kembali`,
                                    "error"
                                );
                            }
                        });
                }
            });

            ev.preventDefault();
        },
    },
    mounted() {
        const e = this.$refs;
        var self = this;

        //upload excel
        Dropzone.autoDiscover = false;
        let myDropzone = new Dropzone(this.$refs.dzone, {
            uploadMultiple: false,
            maxFiles: 1,
            createImageThumbnails: false,
            autoProcessQueue: false,
            url: "getMeSomeUrl",
            accept: function (file, done) {
                console.log("accept", file, done);
                this.removeFile(file);
            },
            success: function (file) {
                this.removeFile(file);
            },
        });
        myDropzone.on("addedfile", (file) => {
            this.loading = true;
            this.file = file;
            setTimeout(() => {
                this.loading = false;
                this.msg = file.name;
            }, 2000);
            console.log(`File added: ${file.name}`);
            console.log(file);
            // return;
            // this.loading = true;
            // var formData = new FormData();
            // formData.append('userfile', file);
            // console.log('formdata', formData);
            // authFetch('/order/rekonsiliasi/import', {
            //     method: 'POST',
            //     body: formData,
            //     headers: {
            //     'Content-Type': null,
            //     }
            // }).then(res => res.json())
            // .then(json => {
            //         this.loading = false;
            //     if(json.success)
            //     {
            //         this.formData = json.data;
            //         this.totalAwb = json.data.length;
            //     }
            //     else{
            //         Swal.fire('gagal', '', 'error');
            //     }
            // });
        });
        myDropzone.on("maxfilesexceeded", function (file) {
            myDropzone.removeAllFiles();
        });

        // this.table = createTable(e.tblppobproduct, {
        //     title: "Preview Produk PPOB",
        //     serverSide: false,
        //     columns: [
        //         { data: "product_category_check" },
        //         { data: "product_name_check" },
        //         { data: "product_code_check" },
        //         { data: "product_tag_check" },
        //         { data: "product_type_check" },
        //         { data: "product_cost_old" },
        //         { data: "product_cost_new" },
        //         { data: "product_price_old" },
        //         { data: "product_price_new" },
        //         { data: "product_admin_provider_check" },
        //         { data: "product_admin_shipper_check" },
        //     ],
        //     /* "ajax": "/examples/transient_data",
        //      */
        // });
    },
};
</script>
<style scoped>
.drag-area {
    border: 2px dashed rgb(114, 114, 114);
    min-height: 250px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.drag-area.active {
    border: 2px solid rgb(133, 133, 133);
}

.drag-area .icon {
    font-size: 100px;
    color: rgb(139, 139, 139);
}

.drag-area header {
    font-size: 30px;
    font-weight: 500;
    color: rgb(126, 126, 126);
}

.drag-area span {
    font-size: 25px;
    font-weight: 500;
    color: rgb(129, 129, 129);
    margin: 10px 0 10px 0;
}

.drag-area button {
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 500;
    border: none;
    outline: none;
    background: rgb(206, 206, 206);
    color: #5256ad;
    border-radius: 5px;
    cursor: pointer;
}

.drag-area img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
}
</style>